<template>
    <div>
        <div class="listing" style="position: relative;">
            <div class="sidebar-slider" v-show="showAssignSlide">
                <v-navigation-drawer
                    absolute
                    v-model="showAssignSlide"
                    right
                    hide-overlay
                    :height="drawerHeight"
                    width="50%"
                    class="merchandise-drawer"
                    >
                        <v-app-bar color="#E0F5F5" elevation="0">
                            <div class="title">
                                <hb-icon class="ml-n1">mdi-square-edit-outline</hb-icon> Assign Products ({{ selectedRows.length }} Products)
                            </div>
                            <v-spacer></v-spacer>
                            <v-row justify="end">
                                <hb-btn icon tooltip="Close" @click="hideSlideEvent" active-state-off>
                                mdi-close
                                </hb-btn>
                            </v-row>
                        </v-app-bar>
                        <div class="main-content-box white">
                            <div class="description-box hb-text-night-light">
                                Create 2-3 sentences that describes the overview of this form.
                            </div>
                            <hb-form label="Assign Products to Properties" full>

                                <hb-combobox
                                    v-model="itemsSelected"
                                    :items="propertyItems"
                                    id="primary_properties"
                                    name="primary_properties"
                                    label="Select Properties"
                                    data-vv-scope="default"
                                    data-vv-name="primary_properties"
                                    data-vv-as="Properties"
                                    :error="errors.has('default.primary_properties')"
                                    select
                                    item-value="id"
                                    item-text="name"
                                    return-object
                                >
                                </hb-combobox>
                            </hb-form>
                        </div>
                        <v-app-bar color="white" class="footer-actions" absolute bottom elevation="0">
                            <v-spacer></v-spacer>
                            <div>
                                <hb-link class="mr-2" @click="hideSlideEvent">Cancel</hb-link>
                                <hb-btn color="primary" :disabled="isAssigning" :loading="isAssigning" @click="assignPropertiesEvent">Save</hb-btn>
                            </div>
                        </v-app-bar>
                </v-navigation-drawer>
            </div>
            <hb-data-table 
                v-if="isLoadingData || corporate_merchandises.length" 
                :headers="tableColumns"
                :loading="isLoadingData" 
                :items="corporate_merchandises"
                :show-select="showAssignSlide"
                v-model="selectedRows"
                fixed-header
                :search="search"
                class="corporate-merchand-table"
                >
                <template v-slot:item.data-table-select="{ isSelected, select }">
                    <v-simple-checkbox color="primary" :value="isSelected" @input="select($event)"></v-simple-checkbox>
                </template>

                <template v-slot:item.sku="{ item }">
                    <hb-tooltip v-if="item.sku && item.sku.length > 10">
                        <template v-slot:item>
                            {{ trimTextEvent({ value: item.sku, limit: 10 }) }}
                        </template>
                        <template v-slot:body>
                            {{ item.sku }}
                        </template>
                    </hb-tooltip>
                    <span v-else>{{ item.sku }}</span>
                </template>

                <template v-slot:item.name="{ item }">
                    <hb-tooltip>
                        <template v-slot:item>
                            {{ trimTextEvent({ value: item.name, limit: 10 }) }}
                        </template>
                        <template v-slot:body>
                            {{ item.name }}
                        </template>
                    </hb-tooltip>
                </template>

                <template v-slot:item.default_price="{ item }">
                    <span>${{ item.default_price }}</span>
                </template>

                <template v-slot:item.default_cost="{ item }">
                    <span>${{ item.default_cost }}</span>
                </template>
                
                <template v-slot:item.total_cost_product="{ item }">
                    <span>${{ item.total_cost_product }}</span>
                </template>
                
                <template v-slot:item.description="{ item }">
                    <hb-tooltip max-width="500px">
                        <template v-slot:item>
                            {{ trimTextEvent({ value: item.description, limit: 5 }) }}
                        </template>
                        <template v-slot:body>
                            {{ item.description }}
                        </template>
                    </hb-tooltip>
                </template>
                <template v-slot:item.archived="{ item }">
                    <span v-if="item.archived">
                        <hb-icon class="pr-2" mdi-code="mdi-table-actions-disable"></hb-icon>
                    </span>
                    <span v-else>
                        <hb-icon class="pr-2" mdi-code="mdi-table-actions-enable"></hb-icon>
                    </span>
                </template>
                <template v-slot:item.actions="{ item }">
                    <hb-menu options align-right>
                        <v-list>
                            <v-list-item @click="editProductEvent(item)">
                                <v-list-item-title>Edit Product</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="showDeleteDialogEvent(item)">
                                <v-list-item-title>Remove Product</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="archiveProductEvent(item)">
                                <v-list-item-title>{{ item.archived ? 'Active' : 'InActive' }}</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </hb-menu>
                </template>

                <template v-slot:item.last_inventory_update="{ item }">
                   <p class="mb-0">{{ item.last_inventory_update | formatLocalDateTimeServices }}</p>
                   <hb-tooltip v-if="item.updated_by">
                        <template v-slot:item>
                            <p class="hb-text-night-light mb-0">By: {{ trimTextEvent({ value: item.updated_by, limit: 5 }) }}</p>
                        </template>
                        <template v-slot:body>
                            {{ item.updated_by }}
                        </template>
                    </hb-tooltip>
                </template>
                <template
                    v-if="isLoadingData || corporate_merchandises.length" 
                    v-slot:footer
                >
                    <div class="pa-3 pl-5 cm-footer">
                        Total Inventory Cost: <strong>${{ total }}</strong>
                    </div>
                </template>
            </hb-data-table>
        </div>

        <div class="no-data-box" v-if="!isLoadingData && !corporate_merchandises.length">
            <p class="hb-text-light">Add merchandise to create property inventory.</p>
            <hb-btn @click="addProductDialog = true">Add Merchandise</hb-btn>
        </div>

        <add-product v-model="addProductDialog" :selected="selected" @close="addProductDialog=false" @reset-selected="selected=null" @fetchProducts="fetchProducts" />

        <hb-modal v-model="deleteDialog" size="medium" title="Delete Product" confirmation>
            <template v-slot:content>
                <div class="px-6 py-4">
                    Are you sure you want to delete this product?
                </div>
                <div class="px-6 pb-4">
                    If you do, you will lose all the information regarding this product.
                </div>
            </template> 
            <template v-slot:right-actions>
                <hb-btn color="destructive" :disabled="isDeleting" @click="deleteProductEvent">Delete</hb-btn>
                <span v-show="isDeleting">
                    <loader color="#00b2ce" size="20px" class="inline-loader"></loader>
                </span>
            </template>
        </hb-modal>
    </div>
</template>

<script type="text/babel">
import api from "../../../assets/api";
import Loader from "../../assets/CircleSpinner.vue";
import moment from 'moment';
import { notificationMixin } from  '@/mixins/notificationMixin.js';
import { mapGetters } from 'vuex';

export default {
    name: "CorporateMerchandise",
    mixins: [ notificationMixin ],
    components: {
        'add-product': () => import('./AddProduct.vue'),
        Loader
    },
    props: {
        showAssignSlide: {
            type: Boolean,
            default: false
        },
        search: {
            type: String,
            default: ""
        }
    },
    data() {
        return {
            tableColumns: [
                { text: "SKU", value: "sku" },
                { text: "Name", value: "name", sortable: false },
                { text: "Description", value: "description", sortable: false },
                { text: "Default Price", value: "default_price", sortable: false },
                { text: "Default Cost", value: "default_cost", sortable: false },
                { text: "Notification Threshold", value: "threshold", sortable: false },
                { text: "Total Inventory", value: "total_inventory", sortable: false },
                { text: "Total Cost of Product", value: "total_cost_product", sortable: false },
                { text: "Last Inventory Update", value: "last_inventory_update", sortable: false },
                { text: "# of Properties", value: "no_of_properties", sortable: false },
                { text: "Active", value: "archived", sortable: false },
                { text: "", value: "actions", sortable: false },
            ],
            corporate_merchandises: [],
            isLoadingData: false,
            pagination: {},
            query: '',
            addProductDialog: false,
            selected: null,
            showDrawer: false,
            itemsSelected: [],
            deleteDialog: false,
            deleteItem: null,
            isDeleting: false,
            isAssigning: false,
            selectedRows: [],
            total: 0,
            drawerHeight: '100%',
        }
    },
    created() {
        this.fetchProducts();
    },
    mounted() {
        this.setDrawerHeight();
    },
    computed: {
        tableHeight() {
            let documentHeight = document.body.clientHeight;
            let tableElement = document.getElementsByClassName('corporate-merchand-table');
            if (tableElement && tableElement[0]) {
                console.log('tableElement :>> ', tableElement, documentHeight)
                let tableTop = tableElement[0].getBoundingClientRect().top;
                return `${documentHeight - tableTop}px`;
            }
            return '100%';
        },
        ...mapGetters({
            primaryRoleProperties: 'propertiesStore/primaryRoleProperties',
            primaryRoleId: 'authenticationStore/getPrimaryRoleId'
        }),
        propertyItems() {
            return this.primaryRoleProperties.map(p => {
                return {
                    id: p.id,
                    name: (p.number ? p.number + ' - ' : '') + (p.city ? p.city + ' - ' : '') + p.name,
                    number: p.number,
                    city: p.city,
                    gds_id: p.gds_id,
                    disabled: false,
                }
            })
        }
    },
    watch: {
        deleteDialog: function(newValue) {
            if (!newValue) {
                this.deleteItem = null;
            }
        }
    },
    methods: {
        setDrawerHeight() {
            let documentHeight = document.body.clientHeight;
            let tableElement = document.getElementsByClassName('corporate-merchand-table');
            if (tableElement && tableElement[0]) {
                let tableTop = tableElement[0].getBoundingClientRect().top;
                this.drawerHeight = `${Math.ceil((documentHeight - tableTop) - 2)}px`;
                this.$emit('set-sidebar-height', documentHeight - tableTop);
            }
        },
        async fetchProducts() {
            try {
                this.isLoadingData = true;
                let response = await api.get(this, api.MERCHANDISE);
                let { merchandise, total_summary } = response;
                if (!merchandise?.length) {
                    this.$emit('hide-filters', true);
                } else {
                    this.$emit('hide-filters', false);
                    this.corporate_merchandises = [];
                }
                this.corporate_merchandises = merchandise ? merchandise.map(item => {
                    let fullName = item.modified_by_first && item.modified_by_last ? (item.modified_by_first + " " + item.modified_by_last) : '';
                    return {
                        id: item.id,
                        sku: item.sku,
                        product_id: item.product_id,
                        name: item.name,
                        description: item.description,
                        default_price: item.price ? item.price.toFixed(2) : 0,
                        default_cost: item.default_cost ? item.default_cost.toFixed(2) : 0,
                        threshold: item.inventory_threshold ? item.inventory_threshold : 0,
                        total_cost_product: item.summary_inventory.total_default_cost ? item.summary_inventory.total_default_cost.toFixed(2) : 0,
                        total_inventory: item.summary_inventory && item.summary_inventory.total_remaining_inventory ? item.summary_inventory.total_remaining_inventory : 0,
                        last_inventory_update: item.summary_inventory && item.summary_inventory.modified_at && moment(item.summary_inventory.modified_at).isValid() ? item.summary_inventory.modified_at : '',
                        no_of_properties: item.Properties ? item.Properties.length : 0,
                        updated_by: fullName && fullName.trim() ? fullName.trim() : '',
                        archived: item.status ? false : true

                    }
                }) : [];
                this.total = total_summary && (total_summary.total_cost !== 'null' || total_summary.total_cost !== null) ? Number.parseFloat(total_summary.total_cost).toFixed(2) : 0;
            } catch (error) {
                this.showMessageNotification({ description: error });
            } finally {
                this.isLoadingData = false;
            }
        },
        async editProductEvent(params) {
            try {
                let response = await api.get(this, api.MERCHANDISE + params.id);
                let { merchandise } = response;
                this.selected = Object.assign({}, merchandise ? merchandise : null);
                this.addProductDialog = true;
            } catch (error) {
                this.showMessageNotification({ description: error });
            }
        },
        showDeleteDialogEvent(params) {
            this.deleteItem = params;
            this.deleteDialog = true;
        },
        async deleteProductEvent() {
            try {
                this.isDeleting = true;
                await api.delete(this, api.MERCHANDISE + this.deleteItem.id);
                this.deleteDialog = false;
                this.fetchProducts();
            } catch (error) {
                this.showMessageNotification({ description: error });
            } finally {
                this.isDeleting = false;
            }
        },
        async assignPropertiesEvent() {
            try {
                this.isAssigning = true;
                let payload = {
                    Products: this.selectedRows.map(item => { return { id: item.product_id }}),
                    Properties: this.itemsSelected.map(item => { return { id: item.id }}),
                }
                await api.post(this, api.MERCHANDISE + 'assign', payload);
                this.hideSlideEvent();
                this.fetchProducts();
            } catch (error) {
                this.showMessageNotification({ description: error });
            } finally {
                this.isAssigning = false;
            }
        },
        async downloadReport() {
            try {
                let url = api.REPORTS + "download-pdf";
                let timeZone = moment.tz.guess();
                let filters = {
                    "search": {
                        "search": "",
                        "report_date": ""
                    },
                    "columns": [
                        {
                            "key": "merchandise_sku",
                            "width": 150,
                            "label": "SKU",
                            "input": "text",
                            "options": [],
                            "agg_method": "Count",
                            "sort": 1,
                            "column_type": "string",
                            "format": "",
                            "sortable": true,
                            "fixed": false,
                            "group": "merchandise",
                            "search": "",
                            "editable": false,
                            "hide": false,
                            "header_tool_tip": false,
                            "aggregate_enable": false
                        },
                        {
                            "key": "merchandise_name",
                            "width": 150,
                            "label": "Name",
                            "input": "text",
                            "options": [],
                            "agg_method": "Count",
                            "sort": 2,
                            "column_type": "string",
                            "format": "",
                            "sortable": true,
                            "fixed": false,
                            "group": "merchanidse",
                            "search": "",
                            "editable": false,
                            "hide": false,
                            "header_tool_tip": false,
                            "aggregate_enable": false
                        },
                        {
                            "key": "merchandise_default_price",
                            "width": 150,
                            "label": "Default Price",
                            "input": "text",
                            "options": [],
                            "agg_method": "Count",
                            "sort": 3,
                            "column_type": "money",
                            "format": "",
                            "sortable": true,
                            "fixed": false,
                            "group": "merchandise",
                            "search": "",
                            "editable": false,
                            "hide": false,
                            "header_tool_tip": false,
                            "aggregate_enable": false
                        },
                        {
                            "key": "merchandise_default_cost",
                            "width": 150,
                            "label": "Default Cost",
                            "input": "text",
                            "options": [],
                            "agg_method": "Count",
                            "sort": 4,
                            "column_type": "money",
                            "format": "",
                            "sortable": true,
                            "fixed": false,
                            "group": "merchandise",
                            "search": "",
                            "editable": false,
                            "hide": false,
                            "header_tool_tip": false,
                            "aggregate_enable": false
                        },
                        {
                            "key": "merchandise_total_cost",
                            "width": 150,
                            "label": "Total Cost of Product",
                            "input": "text",
                            "options": [],
                            "agg_method": "Count",
                            "sort": 4,
                            "column_type": "money",
                            "format": "",
                            "sortable": true,
                            "fixed": false,
                            "group": "merchandise",
                            "search": "",
                            "editable": false,
                            "hide": false,
                            "header_tool_tip": false,
                            "aggregate_enable": false
                        },
                        {
                            "key": "merchandise_total_inventory",
                            "width": 150,
                            "label": "Total Inventory",
                            "input": "text",
                            "options": [],
                            "agg_method": "Count",
                            "sort": 5,
                            "column_type": "number",
                            "format": "",
                            "sortable": true,
                            "fixed": false,
                            "group": "merchandise",
                            "search": "",
                            "editable": false,
                            "hide": false,
                            "header_tool_tip": false,
                            "aggregate_enable": false
                        },
                        {
                            "key": "merchandise_total_properties",
                            "width": 150,
                            "label": "# of properties",
                            "input": "text",
                            "options": [],
                            "agg_method": "Count",
                            "sort": 6,
                            "column_type": "number",
                            "format": "",
                            "sortable": true,
                            "fixed": false,
                            "group": "merchandise",
                            "search": "",
                            "editable": false,
                            "hide": false,
                            "header_tool_tip": false,
                            "aggregate_enable": false
                        },
                        {
                            "key": "merchandise_last_inventory_update",
                            "width": 150,
                            "label": "Last inevntory update",
                            "input": "timeframe",
                            "options": [],
                            "agg_method": "Count",
                            "sort": 7,
                            "column_type": "date",
                            "format": "",
                            "sortable": true,
                            "fixed": false,
                            "group": "merchandise",
                            "search": {
                                "label": "",
                                "days": 0,
                                "period": "",
                                "relation": "",
                                "start_date": "",
                                "end_date": ""
                            },
                            "editable": false,
                            "hide": false,
                            "header_tool_tip": false,
                            "aggregate_enable": false
                        }
                    ],
                    "sort": {
                        "field": "product_name",
                        "dir": "ASC"
                    },
                    "pivot_mode": {
                        "type": "",
                        "column": {},
                        "row": {},
                        "pivot_field": {},
                        "agg_method": ""
                    },
                    "groups": [],
                    "limit": 50,
                    "page": 1,
                    "offset": 0,
                    "roles_id": [this.primaryRoleId]
                }
                let data = {
                    "filters": JSON.stringify(filters),
                    "type": "generic",
                    "report_type": "merchandise_corporate",
                    "format": "web",
                    "name": "Merchandise Corporate",
                    "timeZone": timeZone,
                    "roles_id": [this.primaryRoleId]
                }
                this.showMessageNotification({ type: 'success', description: 'Your download should begin shortly.' });
                let response = await api.post(this, url, data);
                const arr = new Uint8Array(response?.data);
                var blob = new Blob([arr], {type: 'application/pdf' });
                var link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download =  'merchandise-report';
                link.click();
            } catch (error) {
                this.showMessageNotification({ description: error });
            }
        },
        trimTextEvent(params = {  value: '', limit: 10}) {
            let { value, limit } = params;
            if (value && value.length > limit)
                return value.substring(0, limit) + '...';
            return value;
        },
        hideSlideEvent() {
            this.selectedRows = [];
            this.itemsSelected = [];
            this.$emit('hide-slide');
        },
        async archiveProductEvent(params) {
            try {
                this.isLoadingData = true;
                let payload = {
                    "archived": params.archived ? false : true
                }
                await api.put(this, api.MERCHANDISE + params.id + '/archive', payload);
                await this.fetchProducts();
            } catch (error) {
                this.showMessageNotification({ description: error });
            } finally {
                this.isLoadingData = false;
            }
        }
    },
    destroyed(){
        this.errors.clear();
        this.$validator.reset();
        this.$validator.errors.clear();
    }
}
</script>


<style lang="scss" scoped>
.no-data-box {
    padding: 30px 0;
    text-align: center;
    border: 1.5px dashed #ccc;
    border-radius: 3px;
}

.cm-footer {
    border: thin solid rgba(0, 0, 0, 0.12);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
}

.merchandise-drawer {
    height: 100%;
    top: 36px;
    transform: translateX(0%);
    z-index: 11;
    width: 50%;
    box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
}
.description-box {
    padding-left: 16px;
    padding-top: 12px;
    padding-bottom: 12px;
    border-top: 1px solid #dfe3e9;
    border-bottom: 1px solid #dfe3e9;
}
.footer-actions {
    border-top: 1px solid #dfe3e9 !important;
}
</style>